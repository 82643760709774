import axios from 'axios';

export const createMessage = async (data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/messages`;

    return axios.post(ApiUrl, data).then((response) => response.data);
}

export const createMessagePicture = async (data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/messages/picture`;

    return axios.post(ApiUrl, data).then((response) => response.data);
}