import {
  Avatar,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Paper,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Slide,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import SendIcon from '@mui/icons-material/Send';
import './Chat.css';
import { useNavigate } from 'react-router-dom';
import { fDateTime } from '../../utils/formatTime';
import { updateAnnonce } from 'src/service/annonceApi';
import { createMessage, createMessagePicture } from 'src/service/messageApi';
import { getConversation } from 'src/service/conversationApi';
import { getCheckout, createCheckout } from 'src/service/checkoutApi';
import { notifySuccess } from 'src/service/notificationService';
import { ShButton } from 'src/service/componentService';
import { ToastContainer } from 'react-toastify';
import CustomSpinner from 'src/pages/CustomSpinner';
import ImageIcon from '@mui/icons-material/Image';
import ImageUploading from 'react-images-uploading';
import { Image } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';

export default function Chat({ annonce }) {
  const navigate = useNavigate();
  const componentRef = useRef();
  const listRef = useRef(null);
  const [action, setAction] = useState('');
  const [objectStatus, setObjectStatus] = useState(annonce.status)
  const [handleOnAction, setHandleOnAction] = useState('');
  const [open, setOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [conversationKey, setConversationKey] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [actionSentence, setActionSentence] = useState('');
  const [actionMessage, setActionMessage] = useState('');
  const [images, setImages] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);
  const [loadingSendMessage, setLoadingSendMessage] = useState(false)
  const [prevMessagesLength, setPrevMessagesLength] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogRestitution, setDialogRestitution] = useState(false)
  const [dialoguePicture, setDialoguePicture] = useState(null);
  const [notOkMessage, setNotokMessage] = useState(
    `Bonjour, nous sommes désolé mais votre description et informations d’identification ne correspondent pas à celles de l’objet trouvé concerné !\n` +
    `Mais pas de panique ! Tous les objets sont mis en ligne au fur et à mesure qu’ils sont retrouvés donc n’hésitez pas à consulter la plateforme Sherlook régulièrement !\n` +
    `Toute l’équipe des Vieilles Charrues vous remercie et vous souhaite un bon festival !\n`
  );
  const [okMessage, setOkMessage] = useState(
    'Bonjour, nous sommes heureux pour vous !\n' +
    'Votre description et informations d’identification correspondent bien à celles de l’objet que nous avons retrouvé !\n' +
    'Pour le récupérer en main propre : : \n' +
    `Rendez-vous directement au point information du festival de 11H30 à 23H avec votre n° d’identification : ${annonce.id} \n` + 
    "Après le festival aux bureaux des Vieilles Charrues lundi 14H 18H puis du mardi au vendredi de 10H à 18H. \n" +
    "Par ailleurs pendant et après le festival , on peut procéder à l'envoi postal , les frais d'envoi restant à votre charge."
  );
  const [bonDeLivraisonMessage, setBonDeLivraisonMessage] = useState(
    'Bonjour ! Nous sommes heureux de pouvoir vous rendre votre objet ! \n' +
    'Un bordereau de livraison vient de vous être envoyé sur votre mail !\n' +
    'Une fois rempli, vous recevrez votre objet entre 2 et 5 jours ouvrés !\n' + 
    'A très vite !'
  )

  useEffect(() => {
    fetchConversations();
  }, [message, isSend]);

  useEffect(() => {
    const id = setInterval(fetchConversations, 5000);
    return () => clearInterval(id);
  }, [conversationKey]);

  useEffect(() => {
    if (message === '') {
      setImageUpload(null)
      setImages(null)
    }
  }, [message])

  useEffect(() => {
    if (listRef.current && currentMessages.length > prevMessagesLength) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
        setPrevMessagesLength(currentMessages.length); 
    }
  }, [currentMessages]);

  const fetchConversations = async () => {
    try {
      const data = await getConversation(`?annonce=${annonce.id}`)
      data.sort((x, y) => (x.isPending === y.isPending ? 0 : x.isPending ? -1 : 1));
      setConversations(data);

      setCurrentConversation(conversationKey ? data[conversationKey] : data[0]);
      if (data && data[0]) {
        setCurrentMessages(
          conversationKey
            ? data[conversationKey].messages.sort((x, y) => x.id - y.id)
            : data[0].messages.sort((x, y) => x.id - y.id)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleConversation = (conversation, index) => {
    setCurrentConversation(conversation);
    setCurrentMessages(conversation.messages);
    setConversationKey(index);
    setSelectedIndex(index);
  };

  const handleMessageNotOk = async () => {
    const data = {
      conversation: `api/conversations/${currentConversation.id}`,
      content: notOkMessage,
    };

    try {
      await createMessage(data);
      setIsSend(!isSend);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMessageOk = async () => {
    const data = {
      conversation: `api/conversations/${currentConversation.id}`,
      content: okMessage,
    };

    try {
      await createMessage(data);
      setIsSend(!isSend);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMessageLink = async () => {
    let data = {
      annonceId: annonce.id,
      userId: currentConversation.author.id,
    };
    setIsLoading(true);
    try {
      await createCheckout(data).then((data) => {
        setMessage('');
        setHandleOnAction('');
        setAction('');
        setActionSentence('');
        setActionMessage('');
        notifySuccess('Bon de livraison envoyé !');
        navigate(`/dashboard/products/aexpedie`);
      });
    } catch (error) {
      console.error(error);
    }
    const dataMessage = {
      conversation: `api/conversations/${currentConversation.id}`,
      content: bonDeLivraisonMessage,
    };

    try {
      await createMessage(dataMessage);
      setIsSend(!isSend);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImprimerLeBon = async (status) => {
    await getCheckout(annonce.id)
    .then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `bon-de-livraison-${annonce.id}.pdf`;
        link.click();
        handleUpdateAds({
          status: 'ENVOYÉ'
        }, "", "/dashboard/products/envoyes")
      })
  }
  
  const handleMessage = async () => {
    if (message !== '') {
      setLoadingSendMessage(true);
      let data = {
        conversation: `api/conversations/${currentConversation.id}`,
        content: message,
      };

      if (imageUpload) {
        const formData = new FormData();
        formData.append('picture', imageUpload);
        formData.append('conversation', currentConversation.id);
        await createMessagePicture(formData)
      } else
        await createMessage(data);

      setLoadingSendMessage(false);
      setMessage('');
      setImageUpload(null)
      setImages(null)
    }
  };

  const getPicture = (content) => {
    const imageUrl = `data:image/png;base64, ${content}`
    return imageUrl
  }

  const handlePicture = (img) => {
    setImages(img)
    setImageUpload(img[0] ? img[0].file : null);
    setMessage(`*${img[0].file.name}*`)
  }

  const deletePicture = () => {
    setImageUpload(null)
    setImages(null)
    setMessage('')
  }

  const handleUpdateAds = async (data, notifMessage = "", path = "") => {
    try {
      setIsLoading(true);
      await updateAnnonce(annonce.id, data).then((res) => {
        setMessage('');
        setHandleOnAction('');
        setAction('');
        setActionSentence('');
        setActionMessage('');

        if (notifMessage !== '')
          notifySuccess(notifMessage);
        if (path !== '')
          navigate(path);
      })
    } catch (error) {
      console.error(error);
    }
  }

  const handleClickOpen = (action) => {
    switch (action) {
      case 'lien':
        setActionSentence(`Voulez vous envoyer un lien a de restitution à ${currentConversation.author.email} : `);
        setActionMessage(
          "Cette opération est irrémédiable et permettra à la personne d'acceder au formulaire qui créera le bon de livraison"
        );
        setAction('Envoyer un lien');
        setHandleOnAction('lien');
        setOpen(true);
        break;
      case 'restitution':
        setActionSentence(`Voulez vous restituer cet Objet : ${annonce.category.name} ${annonce.id}`);
        setActionMessage('L\'objet sera disponible dans la sections "Objets restitués" ');
        setDialogRestitution(true)
        setAction('Restituer');
        setHandleOnAction('restitution');
        setOpen(true);
        break;
      case 'annulRestitution':
        setActionSentence(`Voulez vous annuler la restitution pour l' objet : ${annonce.category.name} ${annonce.id}`);
        setActionMessage(`L'objet sera disponible dans la section "objets ${annonce.origin.toLowerCase()}s"`);
        setAction('Annuler la restitution');
        setHandleOnAction('annulRestitution');
        setOpen(true);
        break;
      case 'expedition':
        setActionSentence(`Voulez vous envoyer en expédition l' objet : ${annonce.category.name} ${annonce.id}`);
        setActionMessage(`L'objet sera disponible dans la section "objets à expédier"`);
        setAction('A expedier !');
        setHandleOnAction('expedition');
        setOpen(true);
        break;

      case 'annulExpedition':
        setActionSentence(`Voulez vous annuler expédition ?`);
        setActionMessage(`L'objet sera disponible dans la section "objets Trouvés"`);
        setAction("Annuler l'expédition !");
        setHandleOnAction('annulExpedition');
        setOpen(true);
        break;

      default:
        return;
    }
  };

  const handleAction = () => {
    switch (handleOnAction) {
      case 'restitution':
        handleUpdateAds({
            status: "RESTITUÉ"
          }, "objet restitué", "/dashboard/products/restitues")
        setOpen(false);

        break;
      case 'annulRestitution':
        handleUpdateAds({
            status: annonce.origin
          }, "", "/dashboard/products/trouves");
        setOpen(false);
        break;
      case 'expedition':
        handleUpdateAds({
            status: "A_EXPEDIER"
          }, "objet envoyé à l'expédition", "/dashboard/products/aexpedier");
        setOpen(false);
        break;
      case 'annulExpedition':
        handleUpdateAds({
            status: annonce.origin
          }, "envoi annulé", "/dashboard/products/trouves");
        setOpen(false);
        break;
      case 'lien':
        handleMessageLink();
        setOpen(false);
        break;
      default:
        return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialogue = (content) => {
    setDialoguePicture(getPicture(content))
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setDialoguePicture(null)
    setOpenDialog(false)
  }

  return (
    <Box pr={2} pl={2} pt={5}>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2"></Typography>
        </Grid>
      </Grid>

      <Grid
        container
        component={Paper}
        elevation={3}
        sx={{
          width: '100%',
        }}
      >
        <Grid
          item
          xs={3}
          md={4}
          sx={{
            backgroundColor: '#98DEEB',
            borderRight: '1px solid #e0e0e0',
          }}
        >
          <List>
            {/* bouclage des conversations */}
            <Typography variant="body1" p={1}>
              {' '}
              liste des utilisateurs connectés à cette annonce
            </Typography>
            <Divider />
            {conversations.length > 0 &&
              conversations.map((conversation, index) => (
                <ListItem
                  selected={selectedIndex === index}
                  button
                  key={conversation.id}
                  onClick={() => handleConversation(conversation, index)}
                  className="list-user-chat"
                >
                  <ListItemIcon className='avatar-user-chat'>
                    <Avatar
                      alt=""
                      //    src={`${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conversation.annonce.filePath}`}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          sx={{ display: 'inline', fontSize: '13px', overflowWrap: 'break-word' }}
                          component="span"
                          color="text.primary"
                        >
                          {conversation.author.email}
                        </Typography>
                      </>
                    }
                  />
                  {conversation.isPending && (
                    <div
                      style={{
                        backgroundColor: 'red',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                      }}
                    ></div>
                  )}
                </ListItem>
              ))}

            {/* fin bouclage des conversations */}
          </List>
        </Grid>

        <Grid item xs={8} md={8}>
          <List ref={listRef} className="list-message-chat" sx={{ overflowY: 'auto', scrollBehavior: 'smooth' }}>
            <ListItem key="0">
              <Grid container>
                <Grid item xs={12}>
                  <ListItemText align="right" secondary=""></ListItemText>
                </Grid>
              </Grid>
            </ListItem>

            {/* debut des message */}

            {currentMessages.length > 0 &&
              currentMessages.map((message) => (
                <ListItem key={message.id}>
                  <Grid container>
                    <Grid item xs={12} className='message-container-chat'>
                        { message.isPicture ? (
                          <>
                            <Image
                              className={`${message.author ? 'left' : 'right '} picture-chat`}
                              align={message.author ? 'left' : 'right '}
                              src={getPicture(message.content)}
                              onClick={() => {handleOpenDialogue(message.content)}}
                            />
                          </>
                        ) : (
                          <ListItemText
                            className={message.author ? 'left' : 'right '}
                            align={message.author ? 'left' : 'right '}
                            primary={message.content}
                          ></ListItemText>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText
                        align={message.author ? 'left' : 'right '}
                        secondary={fDateTime(message.createdAt)}
                      ></ListItemText>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            {/* <div ref={messagesEndRef}></div>  */}

            {/* fin des message */}
            <Dialog
              fullScreen
              open={openDialog}
              onClose={handleCloseDialog}
              TransitionComponent={Slide}
              TransitionProps={{ direction: 'up' }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
            >
              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <IconButton
                  onClick={handleCloseDialog}
                  aria-label="close"
                  sx={{ color: 'black' }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogActions>
              <DialogContent
                sx={{
                  p: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color:"blue",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                }}
              >
                <Image
                  className={`picture-dialogue-chat`}
                  src={dialoguePicture}
                  onClick={handleCloseDialog}
                />
              </DialogContent>
            </Dialog>
          </List>
        </Grid>

        <Divider />
        <Grid container style={{ padding: '20px', justifyContent: "space-between", alignItems: "center" }}>
          <Grid item xs={1.5} align="left" style={{ flexGrow: 1 }}>
            { imageUpload === null ? (
            <ImageUploading
              value={images}
              onChange={handlePicture}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <Fab
                  color="#ccc"
                  aria-label="add" 
                  onClick={onImageUpload}
                  {...dragProps}
                  className="fab-chat"
                >
                  <ImageIcon />
                </Fab>
              )}
            </ImageUploading>
            ) : (
              <Fab
                  color="#ccc"
                  aria-label="add" 
                  onClick={deletePicture}
                  className="fab-chat"
                >
                  <CloseIcon />
                </Fab>
            )}
          </Grid>
          <Grid item xs={9}>
            <TextField
              autoComplete="off"
              id="outlined-basic-email"
              label="Votre message ..."
              value={message}
              fullWidth
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1.5} align="right">
            {loadingSendMessage ? (
              <>
                <CustomSpinner />
              </>
            ) : (
              <Fab color="#ccc" aria-label="add" className="fab-chat">
                <SendIcon onClick={handleMessage} />
              </Fab>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} p={1}>
          <ShButton
            variant="contained"
            size="large"
            onClick={handleMessageOk}
            sx={{
              backgroundColor: 'green',
            }}
          >
            Je valide{' '}
          </ShButton>
        </Grid>
        <Grid item xs={6} p={1}>
          <ShButton
            variant="contained"
            size="large"
            onClick={handleMessageNotOk}
            sx={{
              backgroundColor: 'red',
            }}
          >
            Je ne valide pas{' '}
          </ShButton>
        </Grid>
      </Grid>
      <Grid container>
          {objectStatus === "TROUVÉ" &&
            <Grid item xs={12} p={1}>
                <ShButton
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: '#97deeb',
                    }}
                    onClick={() => handleClickOpen('lien')}
                >
                  Envoyer bon de livraison{' '}
                </ShButton>
              </Grid>
          }
        {objectStatus === "A_EXPEDIER" &&
          <Grid item xs={12} p={1} ref={componentRef}>
            <ShButton
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: 'gray',
                }}
                onClick={() => handleImprimerLeBon(objectStatus)}
            >
              Imprimer bon de livraison{' '}
            </ShButton>
          </Grid>
        }
        {objectStatus === "ENVOYÉ" &&
        <Grid item xs={12} p={1} ref={componentRef}>
          <ShButton
              variant="contained"
              size="large"
              sx={{
                backgroundColor: 'gray',
              }}
              onClick={() => handleImprimerLeBon(objectStatus)}
          >
            Imprimer bon de livraison{' '}
          </ShButton>
        </Grid>
        }
        {/* boutons expe
        <Grid item xs={12} p={1}>
          {annonce.status !== 'A_EXPEDIER' && (
            <ShButton
              variant="contained"
              size="large"
              sx={{
                width: '100%',
              }}
              onClick={() => handleClickOpen('expedition')}
            >
              A EXPÉDIER
            </ShButton>
          )}
          {annonce.status === 'A_EXPEDIER' && (
            <ShButton
              sx={{
                backgroundColor: 'orange',
                width: '100%',
              }}
              variant="contained"
              size="large"
              onClick={() => handleClickOpen('annulExpedition')}
            >
              ANNULER L' EXPÉDITION !
            </ShButton>
          )}
        </Grid>
        <Grid item xs={12} p={1}>
          <ShButton
            variant="contained"
            size="large"
            sx={{
              width: '100%',
            }}
            onClick={() => handleClickOpen('lien')}
          >
            envoyer un lien de restitution
          </ShButton>
        </Grid>*/}
        <Grid item xs={12} p={1}>
          {annonce.status === 'RESTITUÉ' ? (
            <ShButton
              sx={{
                backgroundColor: 'orange',
                width: '100%',
              }}
              variant="contained"
              size="large"
              onClick={() => handleClickOpen('annulRestitution')}
            >
              ANNULER LA RESTITUTION !
            </ShButton>
          ) : (
            <ShButton
              sx={{
                backgroundColor: 'green',
                width: '100%',
              }}
              variant="contained"
              size="large"
              onClick={() => handleClickOpen('restitution')}
            >
              Objet restitué en main propre !
            </ShButton>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{actionSentence}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{actionMessage}</DialogContentText>
          <br />
          {dialogRestitution &&
            <>
              <TextField
                label="Détail de la restitution (facultatif)"
                fullWidth
            />
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            ne rien faire
          </Button>
          <Button variant="contained" onClick={handleAction}>
            {action}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
