import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

export const getAnnonces = async (params = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces${params}`;

    // Créer une instance de cache
    const cache = setupCache({
      maxAge: 60 * 60 * 1000,
      debug: true
    });

    // Créer une instance d'Axios avec le cache
    const api = axios.create({
      adapter: cache.adapter
    });

    return api.get(ApiUrl, {
      headers: {
        'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
        'Content-Type': 'application/json'
      }
    }).then((response) => response.data);
}

export const createAnnonce = async (data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces`;

    return axios.post(ApiUrl, data, {
        headers: {
          'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
          'Content-Type': 'application/json'
        }
      }).then((response) => response.data);
}

export const updateAnnonce = async (id, data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces/${id}`;
    
    return axios.put(ApiUrl, data, {
        headers: {
          'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
          'Content-Type': 'application/json'
        }
      }).then((response) => response.data);
}

export const updateAnnonceImage = async (id, imageUpload) => {
    const formData = new FormData();
    formData.append('file', imageUpload);

    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces/${id}/image`;

    return axios.post(ApiUrl, formData).then((response) => response.data);
}

export const deleteAnnonce = async (id) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces/${id}`;
    
    return axios.delete(ApiUrl, {
      headers: {
        'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
        'Content-Type': 'application/json'
      }
    }).then((response) => response.data);
}

export const MooveStockage = async (id, newStockageId) => {
  const ApiUrl = `${process.env.REACT_APP_PUBLIC_URL}/annonce/${id}/update-stockage/${newStockageId}`;

  return axios.get(ApiUrl, {
    headers: {
      'Authorization': `Bearer ` + window.localStorage.getItem('authToken'),
      'Content-Type': 'application/json'
    }
  }).then((response) => response.data)

}