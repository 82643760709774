import React, { useContext } from 'react';
import AnnonceContext from '../../../../contexts/AnnonceContext';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DaySelector from './Forms/DaySelector';

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

export default function Step1() {
  const { formAnnonce, setFormAnnonce, date, setDate } = useContext(AnnonceContext);
  const handleChangeDate = (newDate) => {
    setDate(newDate);
    setFormAnnonce({ ...formAnnonce, date: newDate.toISOString() });
  };

  const handleStatus = (e) => {
    setFormAnnonce({ ...formAnnonce, status: e.target.value, origin: e.target.value });
  };

  return (
    <>
      <Stack spacing={2}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="status-radio-buttons-group"
            name="status-radio-buttons-group"
            value={formAnnonce.status}
            onChange={handleStatus}
          >
            <FormControlLabel
              value="TROUVÉ"
              control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
              label="Trouvé"
            />
          </RadioGroup>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Quand ?"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={(newDate) => {
              handleChangeDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <DaySelector setDate={setDate} date={date} />
      </Stack>
    </>
  );
}

    {/*<>
      <Stack spacing={2}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="status-radio-buttons-group"
            name="status-radio-buttons-group"
            value={formAnnonce.status}
            onChange={handleStatus}
          >
            <FormControlLabel
              value="TROUVÉ"
              control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
              label="Trouvé"
            />
          </RadioGroup>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Quand ?"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={(newDate) => {
              handleChangeDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <DaySelector setDate={setDate} date={date} />
        {/*<TextField
          label={"Numéro de chambre / emplacement"}
          value={formAnnonce.ligneBus}
          onChange={handleChambre}
          />

      </Stack>
        </>*/}