import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

export const updateConversation = async (id, data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/conversations/${id}`;

    return axios.put(ApiUrl, data).then((response) => response.data);
}

export const getConversation = async (params = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/conversations${params}`;

        // Créer une instance de cache
        const cache = setupCache({
            maxAge: 60 * 60 * 1000
        });
      
        // Créer une instance d'Axios avec le cache
        const api = axios.create({
            adapter: cache.adapter
        });

    return api.get(ApiUrl).then((response) => response.data);
}

export const getConversationUnread = async (id) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_URL}/conversations/${id}/annonces/unread`;

        // Créer une instance de cache
        const cache = setupCache({
            maxAge: 60 * 60 * 1000
        });
      
        // Créer une instance d'Axios avec le cache
        const api = axios.create({
            adapter: cache.adapter
        });

    return api.get(ApiUrl).then((response) => response.data.result);
}