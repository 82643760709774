import React, { useEffect, useState } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { Button, Row, Col, Toast } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import AuthAPI from './service/authAPI';
import AuthContext from './contexts/AuthContext';
import { getNotification } from './service/notificationApi';
import { ToastContainer } from 'react-toastify';

// ----------------------------------------------------------------------
AuthAPI.setup();

export default function App() {
  const [user, setUser] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(AuthAPI.isAuthenticated());
  const [notifications, setNotifications] = useState([]);
  const [customerId, setCustomerId] = useState(AuthAPI.getCustomerId())
  const contextValue = { isAuthenticated, setIsAuthenticated, user, setUser, notifications, setNotifications, customerId, setCustomerId };

  /*useEffect(() => {
    const id = setInterval(fetchNotifications, 5000);
    return () => clearInterval(id);
  }, []);*/

  /*const fetchNotifications = async () => {
    getNotification().then((resp) => {
      setNotifications(resp);
    })
  };*/

  return (
    <>
      <AuthContext.Provider value={contextValue}>
        <ThemeProvider>
          <ToastContainer />
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </ThemeProvider>
      </AuthContext.Provider>
    </>
  );
}
