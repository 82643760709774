// ----------------------------------------------------------------------

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  off: '/static/mock-images/avatars/apower-outline.png'
};

export default account;
