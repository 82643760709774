import jwtdecode from 'jwt-decode';
import axios from 'axios';


function authenticate(credentials) {
  const ApiUrl = process.env.REACT_APP_PUBLIC_API + '/login';
  return axios
    .post(ApiUrl, credentials)
    .then((response) => {
      return response.data
      })
    .then((data) => {
      window.localStorage.setItem('authToken', data.token);
      window.localStorage.setItem('customerId', data.id);
      axios.defaults.headers['Authorization'] = 'Bearer ' + data.token;

      return true;
    });
}

function logout() {
  window.localStorage.removeItem('authToken');
  window.localStorage.removeItem('customerId');
  delete axios.defaults.headers['Authorization'];

}

function setAxiosToken(token) {
  axios.defaults.headers['Authorization'] = 'Bearer ' + token;
}

function setup() {
  const token = window.localStorage.getItem('authToken');
  if (token) {
    const { exp: expirationToken } = jwtdecode(token);

    if (expirationToken * 1000 > new Date().getTime()) {
      setAxiosToken(token);
    } else {
      //logout();
      return
    }
  } else {
    logout();
  }
}

function isAuthenticated() {
  const token = window.localStorage.getItem('authToken');
  if (token) {
    const { exp: expirationToken } = jwtdecode(token);

    if (expirationToken * 1000 > new Date().getTime()) {
      return true;
    }
    return false;
  }
  return false;
}

function getCustomerId() {
  const customerId = window.localStorage.getItem('customerId');
  if (customerId && isAuthenticated())
    return customerId
  return null
}

export default {
  setup,
  logout,
  isAuthenticated,
  authenticate,
  getCustomerId,
};
