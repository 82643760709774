import React, { useEffect, useState } from "react";
import { getStockages } from "src/service/stockageApi";
import { Button, Grid, Autocomplete, TextField } from "@mui/material";
import { MooveStockage } from "src/service/annonceApi";
import { toast } from "react-toastify";

export default function UpdateStockage(selectedAnnonce) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);


    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        MooveStockage(selectedAnnonce.annonce.id, newValue.id).then(res => {
            if(res.message === "Stockage updated successfully") {
                console.log(res)
                toast.success("Le stockage a été mis à jour !", {
                    position: 'top-center',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("Une erreur est survenue", {
                    position: 'top-center',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        })
    };

    useEffect(() => {
        getStockages("?motherBox=1").then((res) => {
            setOptions(res);
        });
    }, [selectedAnnonce])

    return (
        <>
            <Grid container pb={5} sx={{ justifyContent: "center", alignContent: "center" }}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    getOptionLabel={(option) => option.boxName}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Nouvelle boite" />}
                    onChange={handleOptionChange}
                    value={selectedOption}
                />

            </Grid>
        </>
    )
}