import { toast } from 'react-toastify';

export const notifySuccess = (message, onCloseCallback = null) => {
    toast.success(message, {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: onCloseCallback
    });
}

export const notifyErrorEmpty = (message, onCloseCallback = null) => {
    let errorMessage = "Veuillez remplir tous les champs obligatoires : "
    let count = 0
    message.forEach((m) => {
      if (m !== null) {
        if (count !== 0)
          errorMessage += ", "
        errorMessage += m
        count++;
      }
    });
    toast.error(errorMessage, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
}