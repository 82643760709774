// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Publier',
    path: '/dashboard/publish',
    icon: getIcon('el:bullhorn'),
  },
  /*{
    title: 'Demande d\'objet perdu',
    path: '/dashboard/products/perdus',
    icon: getIcon('el:bullhorn'),
  },*/
  {
    title: 'Objets Trouvés',
    path: '/dashboard/products/trouves',
    icon: getIcon('gg:search-found'),
  },/*  
  {
    title: 'Objets Perdus',
    path: '/dashboard/products/perdus',
    icon: getIcon('akar-icons:question'),
  },*/
  {
    title: 'Objets Restitués',
    path: '/dashboard/products/restitues',
    icon: getIcon('la:hands-helping'),
  },
  {
    title: 'Objets à expédier',
    path: '/dashboard/products/aexpedie',
    icon: getIcon('fa6-solid:truck-fast'),
  },/*
  {
    title: 'Objets Payés à envoyer',
    path: '/dashboard/products/payes',
    icon: getIcon('flat-color-icons:paid'),
  },*/
  {
    title: 'Objets partis en livraison',
    path: '/dashboard/products/envoyes',
    icon: getIcon('akar-icons:shipping-box-v1'),
  },
  {
    title: 'Messages',
    path: '/dashboard/messages',
    icon: getIcon('noto-v1:left-speech-bubble'),
  },
  //  path: '/dashboard/user',
  {
    title: 'Stockages',
    path: '/dashboard/stockage',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Statistiques',
    path: '/dashboard/statistics',
    icon: getIcon('mdi:chart-line'),
  },
  {
    title: 'Vidéo tutoriel',
    path: '/dashboard/settings',
    icon: getIcon('uil:setting'),
  },
];

export default navConfig;