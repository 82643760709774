import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

export const getCustomersAds = async (id, status = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/customers/${id}/annonces${status}`;

    // Créer une instance de cache
    const cache = setupCache({
        maxAge: 60 * 60 * 1000,
        debug: true
      });
  
      // Créer une instance d'Axios avec le cache
      const api = axios.create({
        adapter: cache.adapter
      });

    return api.get(ApiUrl).then((response) => response.data);
}

export const getCustomer = async (customerId) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/customers/` + customerId;

    return axios.get(ApiUrl).then((response) => response.data);
}