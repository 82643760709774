import React, { createContext, useContext, useEffect, useState } from 'react';
import CustomStepper from './FormComponents/CustomStepper';
import AnnonceContext from '../../../contexts/AnnonceContext';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { createAnnonce, updateAnnonceImage } from 'src/service/annonceApi';
import { notifySuccess, notifyErrorEmpty } from 'src/service/notificationService';
import { getCustomer } from 'src/service/customerApi';
import { toast } from 'react-toastify';

const Publier = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formAnnonce, setFormAnnonce] = useState({
    origin: 'TROUVÉ',
    status: 'TROUVÉ',
    ville: 'Les Vieilles Charrues, Carhaix',
    placeDescription: 'Les Vieilles Charrues, Rue de Persivien, Carhaix-Plouguer, France',
  });
  const [currentCategory, setCurrentCategory] = useState();
  const [formAttributes, setFormAttributes] = useState([]);
  const [imageUpload, setImageUpload] = useState();
  const [date, setDate] = useState(new Date().toISOString());

  let navigate = useNavigate();

  useEffect(async () => {
    await getCustomer(window.localStorage.getItem('customerId')).then(res => {
      setFormAnnonce({
        ...formAnnonce,

        origin: 'TROUVÉ',
        status: 'TROUVÉ',
        ville: res.name,
        placeDescription: res.name + ', France',
        professionalId: res.id,
        annonceAttributes: formAttributes,
      })
    })
  }, [formAttributes]);

  const checkStockage = () => {
    if (formAnnonce.stockage === undefined || formAnnonce.stockage === '')
      return false
    return true
  }

  const handleNotifySuccess = () => {
    navigate('/dashboard/products/trouves');
  }

  const handleSubmit = async () => {
    try {
      if (!checkStockage()) {
        toast.error('Merci de renseigner un stockage', {
          position: 'top-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return
      }
      
      const response = await createAnnonce(formAnnonce);
      const annonceId = response.id;

      updateAnnonceImage(annonceId, imageUpload)
      notifySuccess('Annonce ajoutée avec succès', handleNotifySuccess)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AnnonceContext.Provider
      value={{
        formAnnonce: formAnnonce,
        setFormAnnonce: setFormAnnonce,
        currentCategory: currentCategory,
        setCurrentCategory: setCurrentCategory,
        formAttributes: formAttributes,
        setFormAttributes: setFormAttributes,
        handleSubmit: handleSubmit,
        imageUpload: imageUpload,
        setImageUpload: setImageUpload,
        activeStep: activeStep,
        setActiveStep: setActiveStep,
        date: date,
        setDate: setDate,
      }}
    >
      <Page>
        <CustomStepper />
      </Page>
    </AnnonceContext.Provider>
  );
};

export default Publier;
