import React, { useEffect, useState } from 'react';
import axios from 'axios';

const useCheckInternetAndAPI = (apiUrl) => {
  const [apiAccessible, setApiAccessible] = useState(true);

  /*useEffect(() => {
    const checkInternet = async () => {
      try {
        await axios.get('https://www.google.com/generate_204', { 'Cache-Control': 'no-cache' });
        return true;
      } catch {
        return false;
      }
    };

    const checkInternetAndAPI = async () => {

      try {
        const response = await axios.get(apiUrl, { params: {
          t: new Date().getTime(),
        },});

        //console.log(Date.now().toString().slice(0, -3) === response.data.ping.toString())
        //console.log(Date.now().toString().slice(0, -3), response.data.ping.toString())

        if (response.status !== 200) {
          throw new Error('API is not accessible');
        }

        setApiAccessible(true);
        //console.log('API is accessible');
      } catch (error) {
        setApiAccessible(false);
        console.error('Failed to access API:', error);
      }
    };

    // Initial check
    checkInternetAndAPI();

    // Set interval to check every 30 seconds
    const intervalId = setInterval(checkInternetAndAPI, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiUrl]);*/ 

  return { apiAccessible };
};

const PingComponent = () => {
  const apiUrl = 'https://api.sherlook.fr/ping';
  const { apiAccessible } = useCheckInternetAndAPI(apiUrl);

  return (
    <>
      <p style={{color:'black'}}>Connexion API: {apiAccessible ? '🟢' : '🔴'}</p><br/>
    </>
  );
};

export default PingComponent;
