import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';
import videoSrc from "../../src/tuto.mov"

export const Settings = () => {
    const title = 'Tutoriel Sherlook VC 24'; // Titre de la vidéo

    return (
        <Card sx={{ maxWidth: 800, margin: 'auto', mt: 5 }}>
        <CardMedia component="video" src={videoSrc} controls sx={{ height: 450 }} />
        <CardContent>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </CardContent>
      </Card>
    )

}